.c-stepper {
  --circle-size: clamp(1.5rem, 5vw, 3rem); // Moved here
  --spacing: clamp(0.25rem, 2vw, 0.5rem);  // Moved here

  display: flex;

  &__item {
    display: flex;
    flex-direction: column;
    flex: 1;
    text-align: center;

    &--finished-unsuccessful {
      &:before, &:after {
        background-color: #ff3e3e;
      }
    }

    &--finished {
      // Styles for finished items
      //background-color: green; // Example style
      //color: white; // Example style
    }

    &--disabled {
      // Styles for disabled items
      background-color: grey; // Example style
      color: #ccc; // Example style
      pointer-events: none; // Disables interaction
    }

    &:before {
      content: "";
      display: block;
      width: var(--circle-size);
      height: var(--circle-size);
      border-radius: 50%;
      background-color: lightgrey;
      //background-color: red;
      opacity: 0.5;
      margin: 0 auto 1rem;
    }

    &--finished {
      &:before, &:after {
        background-color: green;
      }
    }

    &--current {
      &:before, &:after {
        background-color: yellowgreen;
      }
    }

    &:not(:last-child) {
      &:after {
        content: "";
        position: relative;
        top: calc(var(--circle-size) / 2);
        width: calc(100% - var(--circle-size) - calc(var(--spacing) * 2));
        left: calc(50% + calc(var(--circle-size) / 2 + var(--spacing)));
        height: 2px;
        background-color: #e0e0e0;
        order: -1;
      }
    }
  }

  &__title {
    font-weight: bold;
    font-size: clamp(1rem, 4vw, 1.25rem);
    margin-bottom: 0.5rem;
  }

  &__desc {
    color: grey;
    font-size: clamp(0.85rem, 2vw, 1rem);
    padding-left: var(--spacing);
    padding-right: var(--spacing);
  }
}
